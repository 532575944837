import React, { useState } from "react";
import { Text } from '../../../Pages/Language/Language';
import H4 from "../../Elements/H4";
import P from "../../Elements/P";
import { useSelector } from "react-redux";


// import "./EmployementDetailsComp.css";
// import H4 from "../Elements/H4";
// import P from "../Elements/P";


export default function EmployementDetailsCmp(props) {
  // const [selectedEmployement,setSelectedEmployement]=useState("")


  const cssProperties = useSelector((state) => state.HomeReducer.cssProperties);

  return (
    <section className="app-start">
        <div className="app-body-img">
          <img src="../images/employement_Details.png" alt="vkyc" />
        </div>
        <div className="app-body-data">
          <H4
            className="title"
            title={<Text tid="occupation_details" />}
            // titleClr2={cssProperties?.titleClr2}
            color={cssProperties?.pagetitle_heading?.font_color}
            fontSize={cssProperties?.pagetitle_heading?.font_size}
          />
          <P
            className="txt"
            txt={<Text tid="occupation_details_subtext" />}
            // txtClr={cssProperties.txtClr}
            color={cssProperties?.body?.font_color}
            fontSize={cssProperties?.body?.font_size}
          />
          <hr />
        </div>
        <form className="OccupationSelectionForm">
          {
            props?.ocupationList?.map((ele,ind)=>(
              <>
              <input type="radio" onChange={()=>props.setSelectedEmployement(ele.cusoccpname)} checked={ele.cusoccpname == props.selectedEmployement} id={ele.cusoccpname} className="OccupationSelectionFormRadioButon"  style={{'backgroundColor':`${ele.cusoccpname == props.selectedEmployement ? cssProperties?.button?.color:''}`,'borderColor':`${ele.cusoccpname == props.selectedEmployement ? cssProperties?.button?.color:''}`}}/>
              <label for={ele.cusoccpname} className="form-check-label">{ele.cusoccpname}</label><br/>
              </>
            ))
          }
      </form>
      {/* <h4 style={{'marginTop':'30px'}}>Select the Salery Range</h4> */}
   {props?.selectedEmployement && props?.showSalaryRange &&  <><H4
            className="title"
            title={'Select the Salary Range'}
            // titleClr2={cssProperties?.titleClr2}
            color={cssProperties?.pagetitle_heading?.font_color}
            fontSize={cssProperties?.pagetitle_heading?.font_size}
          />
        <form className="OccupationSelectionForm" style={{marginBottom:"50px"}}>
          {
            props?.saleryList?.map((ele,ind)=>(
              
              <>
              <input type="radio" onChange={()=>props.setSelectedSalery(ele.irvalue)} checked={ele.irvalue == props.selectedSalery} id={ele.irvalue} className="OccupationSelectionFormRadioButon"  style={{'backgroundColor':`${ele.irvalue == props.selectedSalery ? cssProperties?.button?.color:''}`,'borderColor':`${ele.irvalue == props.selectedSalery ? cssProperties?.button?.color:''}`}}/>
              <label for={ele.irvalue} className="form-check-label">{ele.irvalue} Lakhs</label><br/>
              </>
            ))
          }
      </form></> }
      </section>
  );
}
