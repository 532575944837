import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import SelectKYCProcessCmp from "../../Components/SelectKYCProcessCmp";
// import RouteNames from "../../Constants/RouteNames";
// import Header from "../../Layout/Header";
// import AppFooter from "../Common/AppFooter";
// import { Text } from "../Language/Language";
// import CleintNames from "../../Constants/ClientNames";
import { useSelector } from "react-redux";
// import TideSelectKycProcessCmp from "../../Components/TideComponents/TideSelectKycProcessCmp/TideSelectKycProcessCmp";
import SelectKYCProcessCmp from "../../../Components/SelectKYCProcessCmp";
import TideSelectKycProcessCmp from "../../../Components/TideComponents/TideSelectKycProcessCmp/TideSelectKycProcessCmp";
import Header from "../../../Layout/Header";
import AppFooter from "../../Common/AppFooter";
import CleintNames from "../../../Constants/ClientNames";
import EmployementDetailsCmp from "../../../Components/SelectKYCProcessCmp/EmployementDetailsComp/EmployementDetailsComp";
import { useDispatch } from "react-redux";
import { actionCustomerOccupationDetails, actionSubmitCustomerOccupationDetails } from "../../../Store/SagaActions/AadharSagaActions";
import RouteNames from "../../../Constants/RouteNames";
import toast from "react-hot-toast";
import { Text } from "../../Language/Language";


const SelectEmployementDetails = () => {

  const [ocupationList,setOcupationList]=useState([])
  const [selectedEmployement,setSelectedEmployement]=useState("")
  const [saleryList,setSaleryList]=useState([])
  const [selectedSalery,setSelectedSalery]=useState("")
  const dispatch = useDispatch();
  const vcipkey = sessionStorage.getItem("vcipkey");

  useEffect(() => {
    dispatch(actionCustomerOccupationDetails({ callback: getOcuupationListData }));
  }, [])
  
  const getOcuupationListData=(data)=>{
    setOcupationList(data?.cusoccplist)
    setSaleryList(data?.irlist || [])
  }
  let navigate = useNavigate();
    // window.onpopstate = () => {
    //   navigate(null);
    // }
  const clientName = useSelector((state) => state.HomeReducer.clientName);
  const dynamicSettingObj = useSelector((state) => state.HomeReducer.dynamicSettingObj);
  const { app_env,showSalaryRange } = dynamicSettingObj;
  const isClientTideAccess =
    clientName === CleintNames?.TIDEDEV ||
    clientName === CleintNames?.TIDEQA ||
    clientName === CleintNames?.TIDE
      ? true
      : false;

  const showSalaryRangeOption = app_env == "lq_uat" || app_env == "lq_prod" || showSalaryRange == "true" ; ;

  const iskinabank = clientName === CleintNames?.KINABANK ? true : false;
  const isCashbook = clientName === CleintNames?.CASHBOOK ? true : false

  const user = sessionStorage.getItem("user");
  const customerVcipDetails = JSON.parse(user);

  const nextStep = () => {
    // if (customerVcipDetails?.kycstatus === "0") {
    //   if(iskinabank){
    //     navigate(RouteNames.CUSTOMER_ID)
    //   }else{
    //     navigate(RouteNames.AADHAR_KYC_KYC_DIGILOCCKER);
    //   } 
    // } else if (customerVcipDetails?.panstatus === "0") {
    //   if (isClientTideAccess) {
    //     navigate(RouteNames.PAN_CAPTURE, { replace: true });
    //   } else {
    //     if(iskinabank){
    //       navigate(RouteNames.KINA_SELECT_OVD_PROCESS, { replace: true });
    //     }
    //     navigate(RouteNames.SELECT_PAN_KYC_PROCESS, { replace: true });
    //   }
    // } else if (customerVcipDetails?.videocallstatus === "0") {
    //   navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
    // }
  };


  const handelSubmitNavigation=()=>{
    // console.log(selectedEmployement)
    const salaryRangeData = selectedSalery ? `, ${selectedSalery} Lakhs` : "";
    const model = {
      vcipkey: vcipkey,
      // cusoccpname: `${selectedEmployement}, ${selectedSalery} Lakhs`,
      cusoccpname: `${selectedEmployement} ${salaryRangeData}`,
      
    };
    dispatch(actionSubmitCustomerOccupationDetails({ model: model, callback: submitCustmerOcupationDetails }));
  }

  const submitCustmerOcupationDetails=(data)=>{
        // console.log(data,"----->Data")
        if (data?.respcode === "200") {
          navigate(RouteNames.SELECT_VIDEOCALL_LANGAUAGE);
        }
        else{

        }
  }

  return (
    <>
      <Header
        title={
          isClientTideAccess ? (
            <Text tid="identity_verification_process"/>
          ) : (
            isCashbook ? <Text tid="start_your_video_KYC"/>:iskinabank?<Text tid="commence_your_digital_kyc" />:
         <Text tid="details"/>
          )
        }
        isClientTideAccess={isClientTideAccess}
        noarrow = "noarrow"
        navigate={() => navigate(-1)}
        hideHeader={false}
      />
      {/* <h1>Hemant Suryawanshi</h1> */}
      {/* {isClientTideAccess ? (
        <TideHeader
          navigate={() => navigate(-1)}
          text={"Identity verification"}
        />
      ) : (
        <Header
          title={<Text tid="commence_video" />}
          navigate={() => navigate(-1)}
          hideHeader={false}
        />
      )} */}
      {isClientTideAccess ? (
        <article className="app-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "space-between",
              height: "100%",
            }}
          >
            <EmployementDetailsCmp selectedEmployement={selectedEmployement} setSelectedEmployement={setSelectedEmployement} ocupationList={ocupationList} selectedSalery={selectedSalery} setSelectedSalery={setSelectedSalery} showSalaryRange = {showSalaryRangeOption} saleryList={saleryList}/>
            <div className="homepagebutton" style={{backgroundColor: isClientTideAccess ? "#F1F2F3" : "#FFFFFFF2",
            paddingBottom : isClientTideAccess ? "2%" : ""}}>
              <AppFooter
                btnName={<Text tid="start"/>}
                isClientTideAccess={isClientTideAccess}
                navigate={handelSubmitNavigation}
                isDisabled={selectedEmployement === ''}
              />
            </div>
          </div>
        </article>
       ) : ( 
         <article className="app-body">
          <EmployementDetailsCmp selectedEmployement={selectedEmployement} setSelectedEmployement={setSelectedEmployement} ocupationList={ocupationList} selectedSalery={selectedSalery} setSelectedSalery={setSelectedSalery} showSalaryRange={showSalaryRangeOption} saleryList={saleryList}/>
          <div className="homepagebutton" style={{backgroundColor:"#FFFFFFF2",
            paddingBottom :"3%"}}>
            <AppFooter
              btnName={<Text tid="start"/>}
              // navigate={nextStep}
              navigate={handelSubmitNavigation}
              isDisabled={selectedEmployement === '' ||  (showSalaryRangeOption && selectedSalery==='')}
            />
          </div>
        </article> 
      )} 
    </>
  );
};

export default SelectEmployementDetails;
